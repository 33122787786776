@import "../../assets/scss/variables";

.patient-form {
  .p-card {
    border: 1px solid $color-main;
    box-shadow: none;
    cursor: pointer;

    &-body {
      .p-card-content {
        padding: 0;
      }
    }
  }
}

.doctor-info {
  .name {
    font-size: 18px;
    font-weight: 600;
  }

  .duty {
    color: $color-border;
  }
}

.calendar-container {
  width: auto;
  overflow: auto;
  background-color: $color-grey;

}

.calendar {
  display: flex;


  .left-bar {
    min-width: 80px;
    line-height: 118px;
    display: flex;
    flex-direction: column;
    background-color: #F0F0F0;
    color: #96A9CB;
    font-size: 12px;
    text-align: center;
    z-index: 9;
    border-top: 1px solid #000;

    .cell {
      height: 88px;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    overflow-x: auto;
    border-top: 1px solid #000;

    .avatar {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      line-height: 45px;
    }

    &-cell {
      width: 158px;
      height: 62px;
      border: 1px solid $color-border;
      display: flex;
      flex-direction: column;
      gap: 6px;
      background-color: #fff;

      span {
        color: #616161;

        &:first-child {
          font-size: 12px;
          font-weight: 600;
        }

        &:last-child {
          font-size: 12px;
        }
      }
    }
  }

  &-body {
    .body-cell {
      display: flex;

      &-children {
        width: 158px;
        height: 88px;
        min-width: 158px;
        min-height: 88px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-border;
        cursor: pointer;

        .box {
          cursor: pointer;
          width: 100%;
          height: 100%;
          border-radius: 2px;
          display: flex;
          flex-direction: column;
          gap: 6px;
          font-size: 12px;
          justify-content: space-between;
          padding: 6px 10px;
          position: relative;

          .status {
            font-weight: bold;
          }

          &.blue {
            background: #a5bbc0;
            border-left: 4px solid #48a9c5;
          }

          &.yellow {
            background: #f4e98d;
            border-left: 4px solid #ffdd00;
          }

          &.green {
            background: #59fc0d;
            border-left: 4px solid #34cf19;
          }

          .icons {
            i {
              position: absolute;
              font-size: 14px;
              right: 10px;

              &.edit {
                top: 10px;
              }

              &.delete {
                color: $color-main;
                bottom: 10px;
              }
            }
          }

          span {
            &:first-child {
              color: #424242;
            }

            &:last-child {
              color: #353535;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
